import {jsPDF} from "jspdf";
let jsPdfExport80MM = (orientation = "p", unit = "px", format = "a4" || []) => {
    // let pdf = jsPDF(orientation, unit, format);
    // const adobearabic = p    df.loadFile("https://rawcdn.githack.com/kareem0badawy/fonts/d760ae5da6ff7c724ec9f414a32550c7b3bfd438/adobearabic.ttf", true);
   // const adobearabic = p    df.loadFile("https://rawcdn.githack.com/kareem0badawy/fonts/d760ae5da6ff7c724ec9f414a32550c7b3bfd438/adobearabic.ttf", true);

  //   const fontLink = '/fonts/Amiri.ttf';
  //   const fontName = 'Amiri.ttf';
  //   const fontFirstName = 'Amiri';
	//
  // const font = pdf.loadFile(fontLink, true);
  //
  // pdf.addFileToVFS(fontName, font);
  // pdf.addFont(fontName, fontFirstName, "normal");
  // pdf.setFont(fontName);
  //
  // return pdf;
    let pdf = jsPDF(orientation, unit, format);
    // const _fornt = pdf.loadFile("https://alfont.com/wp-content/fonts/thulth-arabic-fonts//alfont_com_IBMPlexSansArabic-Light.ttf", true);
    const _fornt = pdf.loadFile("https://alfont.com/wp-content/fonts/new-arabic-fonts//alfont_com_AlFont_com_XB-Yas-1.ttf", true);
    pdf.addFileToVFS("alfont_com_AlFont_com_XB-Yas-1.ttf", _fornt);

    pdf.addFont("alfont_com_AlFont_com_XB-Yas-1.ttf", "AlHurra", "normal");

    return pdf;
};
export default jsPdfExport80MM;
